import {
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  useReactTable
} from '@tanstack/react-table';
import { isArray, isFunction, isNumber, isObject, isString, times, transform } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import { tv } from 'tailwind-variants';
import { cn, getClassNames, VariantProps } from '~/lib/utils';
import { Checkbox, Truncator } from '..';
import { Icon } from '../Icon';
import LoadingDots from '../v2/feedback/LoadingDots';
import { Table, TableBody, TableCell, TableRow } from './Table';

const tableVariants = tv({
  slots: {
    wrapper: 'max-h-full overflow-auto',
    table: 'w-full',
    expander: 'flex flex-1 items-center overflow-hidden',
    row: 'group/row snap-start'
  }
});

type T = { key: string; value: unknown; terminator?: string };

const toKVArr = obj => transform(obj, (acc, value, key) => acc.push({ key, value }), []) as T[];

interface JSONViewerProps extends VariantProps<typeof tableVariants> {
  data: unknown;
  loading?: boolean;
  estimateSize?: (index: number) => number;

  // Filtering
  globalFilter?: string | FilterFn<T>;
  onGlobalFilterChange?: OnChangeFn<string>;

  // Selection
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  onRowSelected?: (originalRow: T, path: string, value: boolean) => void;

  // Expansion
  expanded?: ExpandedState;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  onRowExpanded?: (originalRow: T) => Promise<void>;

  // Events
  onRowClick?: (originalRow: T) => void;

  // Extra
  isRowActive?: (originalRow: T) => boolean;

  // Options
  emptyMessage?: string;
  maxLeafRowFilterDepth?: number;
  disableVirtualization?: boolean;
  showLoadingWhenRowsExist?: boolean;
  lockHeight?: boolean;
  scrollToIndex?: number;
}

export function JSONViewer({
  data: _data = [],
  loading = false,
  estimateSize,
  globalFilter = '',
  onGlobalFilterChange,
  rowSelection = {},
  onRowSelectionChange,
  onRowSelected = () => null,
  onRowExpanded = () => null,
  onRowClick,
  emptyMessage = 'No results.',
  maxLeafRowFilterDepth = 1,
  disableVirtualization = false,
  showLoadingWhenRowsExist = false,
  lockHeight = false,
  isRowActive,
  scrollToIndex,
  ...rest
}: JSONViewerProps) {
  const classNames = getClassNames(tableVariants, rest);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const enableRowSelection = isFunction(onRowSelectionChange);
  const enableGlobalFilter = isFunction(onGlobalFilterChange);

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const getSubRows = (data: T) => {
    const v = data.value;
    if (isArray(v)) {
      return toKVArr(v).concat({ key: '', value: '', terminator: ']' });
    }
    if (isObject(v)) {
      return toKVArr(v).concat({ key: '', value: '', terminator: '}' });
    }
    return [];
  };

  const table = useReactTable({
    data: toKVArr(_data),
    columns: [
      {
        id: 'data',
        cell: ({ row }) => {
          const { key, value, terminator } = row.original;
          if (terminator) {
            return <div className="pl-2">{terminator}</div>;
          }
          if (isArray(value)) {
            return row.getIsExpanded() ? (
              `${key}: [`
            ) : (
              <div>
                <span>{`${key}: [`}</span>
                <span className="text-gray-400">...</span>
                <span>{`]`}</span>
              </div>
            );
          }
          if (isObject(value)) {
            return row.getIsExpanded() ? (
              `${key}: {`
            ) : (
              <div>
                <span>{`${key}: {`}</span>
                <span className="text-gray-400">...</span>
                <span>{`}`}</span>
              </div>
            );
          }
          if (isString(value)) {
            const str = `${key}: "${value}"`;
            return (
              <Truncator content={str}>
                <div className="flex-1 truncate">{str}</div>
              </Truncator>
            );
          }
          return `${key}: ${value}`;
        }
      }
    ],
    state: {
      globalFilter,
      rowSelection,
      expanded
    },
    // Filtering
    onGlobalFilterChange,
    enableGlobalFilter,
    globalFilterFn: isFunction(globalFilter) ? globalFilter : 'includesString',

    // Selection
    onRowSelectionChange,

    // Expansion
    onExpandedChange: setExpanded,
    getSubRows,
    getRowId: (row, _, parent) => {
      const key = isNumber(row.key) ? `[${row.key}]` : row.key;
      return parent ? `${parent.id}.${key}` : key;
    },

    // Models
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    //Default config
    enableRowSelection: row => enableRowSelection && !row.original.terminator,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth,
    enableSubRowSelection: false
  });

  const rawRows = table.getRowModel().rows;

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rawRows.length,
    overscan: 500,
    estimateSize: useCallback(isFunction(estimateSize) ? estimateSize : () => 25, [])
  });

  let rows: Row<T>[] | VirtualItem[], paddingTop: number, paddingBottom: number;
  if (disableVirtualization) {
    rows = rawRows;
    paddingTop = 0;
    paddingBottom = 0;
  } else {
    rows = rowVirtualizer.virtualItems;
    paddingTop = rows.length > 0 ? rows?.[0]?.start || 0 : 0;
    paddingBottom =
      rows.length > 0 ? rowVirtualizer.totalSize - (rows?.[rows.length - 1]?.end || 0) : 0;
  }

  const onTableRowClick = (row: Row<T>) => {
    onRowClick?.(row.original);
    if (!row.getCanExpand()) {
      return;
    }
    row.toggleExpanded();
    if (!row.getIsExpanded()) {
      onRowExpanded(row.original);
    }
  };

  // Lock height so filtering doesn't shift page
  const [minHeight, setMinHeight] = useState(0);
  useEffect(() => {
    if (lockHeight && !loading && rows.length && !minHeight && tableContainerRef.current) {
      setMinHeight(tableContainerRef.current.clientHeight);
    }
  }, [loading, rows]);

  useEffect(() => {
    if (!loading && scrollToIndex) {
      rowVirtualizer.scrollToIndex(scrollToIndex);
    }
  }, [loading, scrollToIndex]);
  return (
    <div className={classNames.wrapper} style={{ minHeight }} ref={tableContainerRef}>
      <Table
        className={cn(
          !rows?.length || (loading && showLoadingWhenRowsExist) ? 'inline-table' : 'table-fixed',
          classNames.table
        )}
      >
        <TableBody className="overflow-auto">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {(!rows?.length || (loading && showLoadingWhenRowsExist)) && (
            <TableRow className="h-24">
              <TableCell colSpan={1} className="h-full bg-white text-center">
                {loading ? <LoadingDots /> : <span>{emptyMessage}</span>}
              </TableCell>
            </TableRow>
          )}
          {!(loading && showLoadingWhenRowsExist) &&
            rows.map(_row => {
              const row = disableVirtualization ? _row : rawRows[(_row as VirtualItem).index];
              return (
                <TableRow
                  key={row.id}
                  className={cn(
                    row.getCanExpand() ? 'cursor-pointer' : 'cursor-default',
                    isRowActive?.(row.original) && 'bg-indigo-50 hover:bg-indigo-50',
                    classNames.row
                  )}
                  onClick={() => onTableRowClick(row)}
                >
                  {row.getVisibleCells().map((cell, i) => (
                    <TableCell
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      className={cn('p-0 font-mono', row.depth == 0 && 'py-1')}
                    >
                      <div className={cn(classNames.expander)}>
                        {row.depth > 0 &&
                          times(row.depth - (row.original.terminator ? 1 : 0)).map(d => (
                            <div
                              key={d}
                              className="border-l border-gray-300 py-1"
                              style={{
                                marginLeft: `8px`,
                                width: '12px',
                                height: '100%'
                              }}
                            >
                              &nbsp;
                            </div>
                          ))}
                        {row.getCanExpand() && (
                          <Icon
                            name={row.getIsExpanded() ? 'SelectSingle' : 'Disclosure'}
                            className="h-5 w-5 text-gray-400"
                          />
                        )}
                        {!row.getCanExpand() && !row.original.terminator && (
                          <div style={{ width: '1.25rem' }} />
                        )}
                        {row.getCanSelect() && (
                          <Checkbox
                            className="mr-2"
                            checked={row.getIsSelected()}
                            onChange={() => {
                              onRowSelected(row.original, row.id, !row.getIsSelected());
                              row.toggleSelected();
                            }}
                            onClick={e => e.stopPropagation()}
                          />
                        )}
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

import {
  isArray,
  isBoolean,
  isEmpty,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
  keyBy,
  mapValues
} from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Button, Label, MyInput, SideBySide, Tooltip } from '~/components';
import { JSONViewer } from '~/components/v3/JSONViewer';
import { CallApiDocument, FieldType } from '~/generated/graphql';
import { ConnectionFormValues } from '../../connection-config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { isJsonString } from '~/utils';
import { useMutation } from '@apollo/client';

interface EnrichmentInput {
  name?: string;
  type?: FieldType;
  required?: boolean;
}

interface EnrichmentField {
  name: string;
  type: FieldType;
  path: string;
}

interface NameValue {
  name: string;
  value: string;
}

function getFieldType(value: unknown): FieldType {
  if (isArray(value)) {
    return FieldType.Array;
  }
  if (isObject(value)) {
    return FieldType.Object;
  }
  if (isNumber(value)) {
    return FieldType.Number;
  }
  if (isBoolean(value)) {
    return FieldType.Boolean;
  }
  if (isString(value)) {
    return FieldType.String;
  }
  return FieldType.Unknown;
}

function getFieldPayload(value: unknown): string {
  if (isUndefined(value) || isNull(value)) {
    return 'null';
  }
  return value as string;
}

export function EnrichmentTest() {
  const { setValue, watch } = useFormContext<ConnectionFormValues>();

  const method = watch('configuration.method') as string;
  const url = watch('configuration.url') as string;
  const headers = watch('configuration.headers') as NameValue[];
  const params = watch('configuration.parameters') as NameValue[];
  const body = watch('configuration.body') as string;
  const inputMappings = watch('configuration.inputMappings') as EnrichmentInput[];
  const fields = watch('configuration.fields') as EnrichmentField[];
  const authHeader = watch('configuration.auth.header') as NameValue;

  const [response, setResponse] = useState();

  const [callAPI, { loading, error }] = useMutation(CallApiDocument, {
    onCompleted: data => {
      if (isJsonString(data?.callApi)) {
        setResponse(JSON.parse(data.callApi));
      }
    },
    onError: () => {
      setResponse(null);
    }
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    mapValues(
      keyBy(fields ?? [], f => f.path),
      () => true
    )
  );

  useEffect(() => {
    setRowSelection(
      mapValues(
        keyBy(fields ?? [], f => f.path),
        () => true
      )
    );
  }, [fields]);

  const [testPayload, setTestPayload] = useState({});

  const injectVariables = useCallback(
    (str: string) => {
      let s = str;
      inputMappings?.forEach(mapping => {
        s = s?.replaceAll(
          new RegExp(`\\{\\{\\s*${mapping.name}\\s*\\}\\}`, 'gm'),
          getFieldPayload(testPayload[mapping.name])
        );
      });
      return s;
    },
    [inputMappings, testPayload]
  );

  const testBody = useMemo(
    () => (body ? injectVariables(body) : null),
    [body, inputMappings, testPayload]
  );

  const requestError = useMemo(() => {
    // URL
    if (isEmpty(url)) {
      return 'URL is required';
    }
    if (!isEmpty(inputMappings) && !inputMappings?.every(mapping => mapping.name)) {
      return 'Input mappings are invalid';
    }
    if (!isEmpty(headers) && !headers?.every(header => header.name && header.value)) {
      return 'Headers are invalid';
    }
    if (!isEmpty(params) && !params?.every(param => param.name && param.value)) {
      return 'Params are invalid';
    }
    if (method === 'POST' && !isEmpty(testBody) && !isJsonString(testBody)) {
      return 'JSON payload is invalid';
    }
    return null;
    // Test payload
  }, [url, inputMappings, JSON.stringify(headers), JSON.stringify(params), method, testBody]);

  const testAPI = async () => {
    const _url = params?.length
      ? `${url}?${new URLSearchParams(params.reduce((acc, v) => ({ ...acc, [v.name]: injectVariables(v.value) }), {}))}`
      : url;
    const _method = isEmpty(method) ? 'GET' : method;
    const _body = isEmpty(testBody) || method === 'GET' ? null : testBody;
    const _headers = headers?.map(h => ({ key: h.name, value: h.value })) ?? [];
    if (!isEmpty(_body)) {
      _headers.push({ key: 'Content-Type', value: 'application/json' });
    }
    if (!isEmpty(authHeader?.name) && !isEmpty(authHeader?.value)) {
      _headers.push({ key: authHeader.name, value: authHeader.value });
    }
    await callAPI({
      variables: {
        url: _url,
        method: _method,
        headers: _headers,
        body: _body
      }
    });
  };

  return (
    <SideBySide hasSectionWrap styles="space-y-3 w-full" heading="Select fields">
      {!!inputMappings?.filter(input => input.name && input.type)?.length && (
        <div>
          <Label>Test payload</Label>
          <div className="overflow-hidden rounded border border-gray-300">
            {inputMappings
              .filter(input => input.name && input.type)
              .map(input => (
                <div key={input.name} className="flex border-b border-gray-300 last:border-none">
                  <div className="w-[160px] border-r border-gray-300 p-1.5">
                    <p>{input.name}</p>
                  </div>
                  <div className="w-full p-0">
                    <MyInput
                      variant="flat"
                      className="w-full rounded-none !bg-white focus:ring-0"
                      placeholder="Enter a value"
                      value={testPayload[input.name] ?? ''}
                      onChange={e =>
                        setTestPayload({ ...testPayload, [input.name]: e.target.value })
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <div className=" w-full max-w-full  rounded border border-gray-300 bg-white">
        <div className="flex w-full items-center justify-between border-b border-gray-300 p-4">
          <Label>API response</Label>
          <Tooltip content={requestError} disabled={!requestError}>
            <div>
              <Button
                iconEnd="Refresh"
                disabled={!!requestError}
                loading={loading}
                onClick={!requestError ? testAPI : null}
              >
                Refresh
              </Button>
            </div>
          </Tooltip>
        </div>

        <JSONViewer
          data={response}
          rowSelection={rowSelection}
          onRowSelectionChange={() => {}}
          classNames={{
            wrapper: 'h-[250px] py-4 px-2',
            row: 'py-2'
          }}
          onRowSelected={(row, path, selected) => {
            if (selected) {
              setValue('configuration.fields', [
                ...(fields ?? []),
                {
                  name: row.key,
                  type: getFieldType(row.value),
                  path: path
                }
              ]);
            } else {
              setValue('configuration.fields', [...fields.filter(field => field.path !== path)]);
            }
          }}
          emptyMessage={error ? error.message : 'Refresh to retrieve enrichment payload.'}
          loading={loading}
          showLoadingWhenRowsExist={true}
        />
      </div>
    </SideBySide>
  );
}

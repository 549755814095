import * as React from 'react';
import { components, SingleValueProps } from 'react-select';

import { Button, Icon, Label, MyCombobox, MyInput } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Dialog } from '~/components/v3';
import { RemoteFieldTypeFragment } from '~/generated/graphql';
import { useSanitizeIdText } from '~/hooks';
import { capsFirst, fieldTypeIconName, Selectable } from '~/utils';

type DropdownType = Selectable & RemoteFieldTypeFragment;

function formatOptionLabel(data: DropdownType) {
  return (
    <span className="flex space-x-2">
      <Icon name={fieldTypeIconName(data.ptType)} className="h-5 w-5 text-gray-500" />
      <span>{data.label}</span>
    </span>
  );
}

function SingleValue(props: SingleValueProps<DropdownType>) {
  return (
    <components.SingleValue {...props}>
      <span className="flex space-x-2">
        <Icon name={fieldTypeIconName(props.data.ptType)} className="h-5 w-5 text-gray-500" />
        <span>{props.data.label || 'Not specified'}</span>
      </span>
    </components.SingleValue>
  );
}

interface Props {
  show: boolean;
  handleSave: (value: string, type: RemoteFieldTypeFragment | undefined) => void;
  dismiss: () => void;
  input: string;
  remoteFieldTypeId: string | null | undefined;
  connectionName: string | undefined;
  objectType: string;
  fieldTypes: RemoteFieldTypeFragment[];
  connectionId?: string;
}

export const NewFieldDialog = React.memo<Props>(
  ({ show, handleSave, dismiss, connectionId, input, ...props }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const prevTextRef = React.useRef<string>('');

    const { sanitizeIdText, sanitizeIdTextLoading } = useSanitizeIdText();

    const opts = props.fieldTypes.map(item => ({
      ...item,
      value: item.remoteId
    }));

    // Todo -> Add comments
    const [typeValue, setTypeValue] = React.useState<DropdownType | null>(() => {
      if (input) {
        const found = opts.find(ft => ft.remoteId === props.remoteFieldTypeId);
        if (found) {
          return { ...found, value: found.remoteId };
        }
      }
      return null;
    });

    // Todo -> Add comments
    const handleSanitize = React.useCallback(
      async (str: string | undefined) => {
        if (!connectionId || !str || str === prevTextRef.current) {
          return;
        }
        const safeName = await sanitizeIdText(connectionId, str);
        if (!safeName) {
          return;
        }
        prevTextRef.current = safeName;
        if (inputRef.current) {
          inputRef.current.value = safeName;
        }
      },
      [connectionId, sanitizeIdText]
    );

    // Todo -> Add comments
    const localDismiss = React.useCallback(() => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setTypeValue(null);
      dismiss();
    }, [dismiss]);

    // Todo -> Add comments
    const save = React.useCallback(() => {
      if (!inputRef.current?.value) {
        return;
      }
      handleSave(inputRef.current.value, typeValue || undefined);
      localDismiss();
    }, [localDismiss, handleSave, typeValue]);

    // Todo -> Add comments
    React.useEffect(() => {
      void handleSanitize(input);
    }, [handleSanitize, input]);

    // Todo -> Add comments
    const handleKeyDown = async (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        await handleSanitize(inputRef.current?.value);
        save();
      }
    };

    return (
      <Dialog
        show={show}
        initialFocusRef={inputRef}
        size="sm"
        heading={`New ${props.objectType}`}
        onDismiss={localDismiss}
        classNames={{ content: 'overflow-visible' }}
        actions={
          <>
            <Button onClick={localDismiss}>Cancel</Button>
            <Button theme="primary" disabled={sanitizeIdTextLoading} onClick={save}>
              Save
            </Button>
          </>
        }
      >
        <div className="space-y-4" onKeyDown={handleKeyDown}>
          <div className="relative">
            <MyInput
              ref={inputRef}
              name="new-field-name"
              defaultValue={input}
              label="Name"
              placeholder="Type name..."
              onBlur={e => handleSanitize(e.target.value)}
              readOnly={sanitizeIdTextLoading}
            />
            {sanitizeIdTextLoading && (
              <div className="absolute bottom-2.5 right-2 flex items-center">
                <LoadingDots />
              </div>
            )}
          </div>
          <div>
            <Label>
              <span>Type</span>
              <span className="ml-1 font-normal text-gray-600">(Optional)</span>
            </Label>
            <MyCombobox
              components={{ SingleValue }}
              formatOptionLabel={formatOptionLabel}
              value={typeValue}
              options={opts}
              onChange={setTypeValue}
              className="w-full min-w-[143px]"
            />
          </div>
          <span className="flex items-start space-x-1.5 pt-4">
            {capsFirst(String(props.objectType))} will be created in{' '}
            {props.connectionName || 'destination'} after saving your sync configuration.
          </span>
        </div>
      </Dialog>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  NewFieldDialog.displayName = 'NewFieldDialog';
}

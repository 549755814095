import { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { Icon } from '../../Icon';
import clsx from 'clsx';
import { Label } from '~/components/form-components';

export type AutoCompleteOption = {
  /* - Required - */
  label: string;
  value: string;
  /* - Optional - */
  id?: string | number;
  // icon?: ReactNode | string
  iconId?: string;
  disabled?: boolean;
};

export type AutoCompleteProps = {
  variant?: 'outlined' | 'filled';
  label?: string;
  options?: AutoCompleteOption[] | [] | null;
  value?: AutoCompleteOption | null;
  defaultValue?: AutoCompleteOption | null;
  disabled?: boolean;
  noDataMessage?: string;
  placeholder?: string;
  allowNew?: boolean;
  showButton?: boolean;
  onChange?: (option: AutoCompleteOption | null) => void;
  updateOnBlur?: boolean;
};

const AutoComplete = ({
  variant = 'outlined',
  label,
  options,
  value,
  defaultValue,
  placeholder = 'Select...',
  noDataMessage = 'No data found',
  disabled,
  allowNew,
  onChange,
  showButton = true,
  updateOnBlur = false
}: AutoCompleteProps) => {
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<AutoCompleteOption | null>(
    value || defaultValue || null
  );
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleChange = (option: AutoCompleteOption) => {
    setSelected(option);
    onChange && onChange(option);
  };

  const filteredOptions = options?.filter(option => {
    return option.label?.toLowerCase().includes(search?.toLowerCase());
  });

  const isFilled = variant === 'filled';

  const AUTOCOMPLETE_INPUT_WRAPPER_SX = 'relative';
  const AUTOCOMPLETE_INPUT_SX = clsx(
    'relative w-full cursor-default overflow-hidden text-left',
    isFilled && 'rounded-md bg-gray-200',
    !isFilled && 'rounded border border-gray-400 bg-white',
    !isFilled && isInputFocused && 'border-transparent ring-2 ring-indigo-500',
    'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300',
    'sm:text-sm'
  );
  const AUTOCOMPLETE_INPUT_LABEL_SX = clsx(
    isFilled ? 'bg-gray-200' : 'bg-white',
    !isFilled && !isInputFocused && 'shadow-input',
    !isInputFocused && selected?.iconId ? 'mr-0 pl-8' : 'mr-6 pl-2',
    'py-[0.3rem]',
    !showButton ? 'mr-0 pr-2' : 'pr-8',
    'w-full border-none text-sm leading-5 text-gray-800 focus:ring-0'
  );
  const AUTOCOMPLETE_INPUT_BUTTON_ICON = clsx(
    'h-5 w-5',
    isFilled ? 'text-gray-500' : 'text-gray-400'
  );
  const AUTOCOMPLETE_INPUT_BUTTON_SX = 'absolute inset-y-0 right-0 flex items-center pr-1';
  const AUTOCOMPLETE_OPTIONS_SX =
    'absolute z-90 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm';
  const AUTOCOMPLETE_OPTIONS_NO_DATA_MSG_SX =
    'relative cursor-default select-none py-2 px-4 text-gray-700';
  const AUTOCOMPLETE_OPTION_SX = ({ active }) =>
    clsx(
      'relative flex cursor-pointer select-none py-2 px-4 text-gray-700',
      active ? 'bg-gray-100' : 'text-gray-900',
      'hover:bg-indigo-100'
    );

  const handleBlur = () => {
    updateOnBlur && onChange({ value: search, label: search });
    setIsInputFocused(false);
  };
  const handleFocus = () => {
    setIsInputFocused(true);
  };

  useEffect(() => {
    setSelected(value);
  }, [value, setSelected]);

  return (
    <Combobox<AutoCompleteOption> value={selected} onChange={handleChange} disabled={disabled}>
      <div className={AUTOCOMPLETE_INPUT_WRAPPER_SX}>
        {label && (
          <Combobox.Label>
            <Label>{label}</Label>
          </Combobox.Label>
        )}
        <div className={AUTOCOMPLETE_INPUT_SX}>
          {!isInputFocused && selected?.iconId && (
            <Icon
              match={selected?.iconId}
              className="absolute top-1/2 left-2 h-5 w-5 -translate-y-1/2 transform text-gray-400"
            />
          )}
          <Combobox.Input
            className={AUTOCOMPLETE_INPUT_LABEL_SX}
            onChange={event => {
              setSearch(event.target.value);
            }}
            displayValue={(option: AutoCompleteOption) => option?.label || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
          {showButton === true && (
            <Combobox.Button className={AUTOCOMPLETE_INPUT_BUTTON_SX}>
              <Icon name="SelectSingle" className={AUTOCOMPLETE_INPUT_BUTTON_ICON} />
            </Combobox.Button>
          )}
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setSearch('')}
        >
          <Combobox.Options className={AUTOCOMPLETE_OPTIONS_SX}>
            {search && allowNew && (
              <Combobox.Option
                className={AUTOCOMPLETE_OPTION_SX}
                value={{ value: search, label: search }}
              >
                {search}
                {/* Create "{search}" */}
              </Combobox.Option>
            )}
            {search && !filteredOptions.length && !allowNew && (
              <div className={AUTOCOMPLETE_OPTIONS_NO_DATA_MSG_SX}>{noDataMessage}</div>
            )}
            {filteredOptions.length > 0 &&
              filteredOptions?.map((option, idx) => (
                <Combobox.Option
                  className={AUTOCOMPLETE_OPTION_SX}
                  key={option?.id || idx}
                  value={option}
                  disabled={option?.disabled}
                >
                  {option.iconId && <Icon match={option.iconId} className="mr-2 h-5 w-5" />}
                  {option.label}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default AutoComplete;

import { Row } from '@tanstack/react-table';
import { Button, Icon, MyInput } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { IBulkField, SchemaOrFieldKey } from '../components/BulkNamespaceUtil';
import { Dialog } from '~/components/v3';
import { useState } from 'react';
import { useAuth, useToggle } from '~/hooks';
import { isEmpty } from 'lodash';
import { BulkSyncFragment } from '~/generated/graphql';

interface FieldRowMenuProps {
  row: Row<IBulkField>;
  setNamespaceValue: (path: string, key: SchemaOrFieldKey, value: any) => void;
  bulkSync: BulkSyncFragment;
}

export function FieldRowMenu({ row, setNamespaceValue, bulkSync }: FieldRowMenuProps) {
  const { user } = useAuth();

  const field = row.original as IBulkField;
  const [showDialog, toggleDialog] = useToggle();

  const handleOutputName = () => {
    if (!isEmpty(field.userOutputName)) {
      setNamespaceValue(field.path, 'userOutputName', null);
    } else {
      toggleDialog();
    }
  };

  const userOutputNames =
    user.organization?.features?.includes('bulk-sync-user-output-names') ||
    bulkSync?.features?.includes('bulk-sync-user-output-names');

  return (
    <>
      <div className="flex flex-row items-start justify-end space-x-2 text-gray-500">
        {!!field.slowMode && (
          <TooltipIcon
            message={field.slowReason || 'Field will be synced non-incrementally'}
            icon={<Icon name="Turtle" size="sm" />}
          />
        )}
        {!!field.obfuscate && (
          <TooltipIcon
            message={`Values will be obfuscated`}
            icon={<Icon name="Astrisk" size="sm" className={'align-top'} />}
          />
        )}
        {!!field.userOutputName && userOutputNames && (
          <TooltipIcon
            message={`Custom output name: ${field.userOutputName}`}
            icon={<Icon name="TypeString" size="sm" className={'align-top'} />}
          />
        )}

        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="invisible rounded p-[2px] hover:bg-gray-300 group-hover/row:visible">
              <Icon name="DotsH" size="sm" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" portal={false}>
            <DropdownMenuRadioGroup
              onValueChange={v =>
                setNamespaceValue(row.original.path, 'obfuscate', v === 'obfuscate')
              }
            >
              <DropdownMenuRadioItem value="plaintext" checked={!field.obfuscate}>
                Plain text
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value="obfuscate" checked={!!field.obfuscate}>
                Obfuscate
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
            {userOutputNames && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleOutputName} className="flex items-center gap-1">
                  <Icon name="TypeString" />
                  {isEmpty(field.userOutputName) ? (
                    <span>Set custom output name</span>
                  ) : (
                    <span>Remove custom output name</span>
                  )}
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <CustomOutputNameDialog
        name={field.userOutputName}
        show={showDialog}
        onDismiss={name => {
          if (!isEmpty(name)) {
            setNamespaceValue(field.path, 'userOutputName', name);
          }
          toggleDialog();
        }}
      />
    </>
  );
}

interface CustomOutputNameDialogProps {
  show: boolean;
  onDismiss: (name?: string) => void;
  name?: string;
}

function CustomOutputNameDialog({ show, onDismiss, name: _name }: CustomOutputNameDialogProps) {
  const [name, setName] = useState<string>(_name);

  return (
    <Dialog
      show={show}
      onDismiss={onDismiss}
      heading="Set custom column output name"
      actions={
        <>
          <Button onClick={() => onDismiss()}>Cancel</Button>
          <Button theme="primary" disabled={isEmpty(name)} onClick={() => onDismiss(name)}>
            OK
          </Button>
        </>
      }
    >
      <div className="flex flex-col gap-2">
        <p>Specify a column name to override automatic naming</p>
        <MyInput
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Column name"
          onKeyDown={e => {
            if (e.key === 'Enter' && !isEmpty(name)) {
              onDismiss(name);
            }
          }}
        />
      </div>
    </Dialog>
  );
}

import { useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Button,
  FieldTypeSelect,
  Label,
  MyInput,
  Search,
  Section,
  TableTopper,
  TableWrap
} from '~/components';
import { Dialog } from '~/components/v3';
import { FieldType, ModelFieldFragment } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import { FieldsPicker } from '~/pages/models/model-config/fields-picker';
import { ConnectionFormValues } from '../../connection-config';

interface EnrichmentField {
  name: string;
  type: FieldType;
  path: string;
}

export function EnrichmentFields() {
  const { watch, setValue } = useFormContext<ConnectionFormValues>();

  const [search, setSearch] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>();
  useHotkeys(['/'], e => {
    if (searchRef.current) {
      searchRef.current.focus();
      e.preventDefault();
    }
  });

  const [show, toggle] = useToggle();

  const fields = watch('configuration.fields') as EnrichmentField[];

  const handleAddField = (form: AddFieldFormValues) => {
    if (form) {
      setValue('configuration.fields', [
        ...(fields ?? []),
        {
          path: form.sourceName,
          name: form.label,
          type: form.type
        }
      ]);
    }
    toggle();
  };

  const preparedFields = useMemo(
    () =>
      fields?.map(field => ({
        id: field.path,
        label: field.name,
        type: field.type,
        sourceName: field.path,
        published: true,
        userAdded: true
      })) ?? [],
    [fields]
  );

  return (
    <>
      <Section>
        <TableWrap className="min-w-full">
          <TableTopper className="h-16 justify-between space-x-4 bg-white px-4">
            <span>Enrichment fields</span>
            <Search
              onChange={setSearch}
              onReset={() => setSearch('')}
              defaultRef={searchRef}
              tooltip={
                <p>
                  Press <span className="rounded border border-gray-600 bg-gray-700 px-1">/</span>{' '}
                  to search
                </p>
              }
            />
            <div className="space-x-2">
              <Button onClick={toggle}>Add field</Button>
            </div>
          </TableTopper>
          <FieldsPicker
            fields={preparedFields as ModelFieldFragment[]}
            loading={false}
            disabled={false}
            onFieldsChange={fields => {
              setValue(
                'configuration.fields',
                fields.map(field => ({
                  name: field.label,
                  type: field.type,
                  path: field.sourceName
                }))
              );
            }}
            hasWriteinFields={true}
            userTypeSelection={true}
            search={search}
            emptyMessage={
              search.length ? 'No results.' : 'Select fields from the API response or add manually.'
            }
            disableRowSelection={true}
            hideExampleColumn={true}
          />
        </TableWrap>
      </Section>

      <AddFieldDialog show={show} onDismiss={handleAddField} />
    </>
  );
}

interface AddFieldFormValues {
  label: string;
  sourceName: string;
  type: FieldType | null;
  example: string;
}

interface AddFieldDialogProps {
  show: boolean;
  onDismiss: (form?: AddFieldFormValues) => void;
}

function AddFieldDialog({ show, onDismiss }: AddFieldDialogProps) {
  const { handleSubmit, register, reset, formState, control } = useForm<AddFieldFormValues>({
    criteriaMode: 'all',
    defaultValues: {
      label: '',
      sourceName: '',
      type: null,
      example: ''
    }
  });
  const { errors } = formState;

  const save: SubmitHandler<AddFieldFormValues> = form => {
    onDismiss(form);
  };

  return (
    <Dialog
      heading="Add field"
      show={show}
      actions={
        <>
          <Button
            onClick={() => {
              reset();
              onDismiss();
            }}
          >
            Cancel
          </Button>
          <Button theme="primary" onClick={handleSubmit(save)}>
            Add field
          </Button>
        </>
      }
      classNames={{ body: 'p-6 max-w-xs space-y-3', content: 'overflow-visible' }}
    >
      <div>
        <Label htmlFor="sourceName">Source name</Label>
        <MyInput
          {...register('sourceName', { required: 'Source name is required' })}
          //onBlur={handleSourceName}
          errors={errors}
        />
      </div>
      <div>
        <Label htmlFor="label">Label</Label>
        <MyInput
          {...register('label', {
            required: 'Label is required',
            maxLength: { value: 50, message: 'Too many characters' }
          })}
          errors={errors}
        />
      </div>
      <div>
        <Label>Type</Label>
        <FieldTypeSelect<AddFieldFormValues> name="type" control={control} />
      </div>
      {/* <div>
        <Label htmlFor="example">
          Example value <span className="text-gray-500">(optional)</span>
        </Label>
        <MyInput
          {...register('example', {
            maxLength: { value: 200, message: 'Too many characters' }
          })}
          errors={errors}
        />
      </div> */}
    </Dialog>
  );
}

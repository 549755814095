import { ApolloQueryResult, LazyQueryExecFunction, useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FilterValueElements, Icon, MyCombobox, ParamButton, Tooltip } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { RowControl } from '~/components/v3/RowControl';
import {
  BulkSourceSchemaDocument,
  BulkSourceSchemaQuery,
  Exact,
  FieldFilters,
  FieldType,
  FilterFunction,
  SchemaFilters,
  SupportedFilterFunction,
  Term
} from '~/generated/graphql';
import { useFilterFunctions } from '~/hooks';
import { cn } from '~/lib/utils';
import { isContinuation, isPoller, toSelectable, toSelectables } from '~/utils';
import {
  IBulkField,
  IBulkNamespace,
  IBulkSchema,
  getEntitiesFromPath,
  getPathFromEntities
} from '../components/BulkNamespaceUtil';
import { BulkFieldPickerDrawer } from './BulkFieldPickerDrawer';
interface BulkFiltersProps {
  connectionId: string;
  namespaces: IBulkNamespace[];
  initialFilters: {
    schemaID: string;
    fieldID: string;
    function: FilterFunction;
    value: unknown;
  }[];
  handleLoadFields: (row: IBulkSchema) => void;
  refetchSchema: LazyQueryExecFunction<
    BulkSourceSchemaQuery,
    Exact<{
      connectionId: string;
      refresh?: boolean;
      continuation: string;
      namespaceId?: string;
      schemaId?: string;
      schemaFilters?: SchemaFilters;
      fieldFilters?: FieldFilters;
      includeFields: boolean;
    }>
  >;
  bulkSyncSchemaLabel: Term;
  filters: BulkFilter[];
  onChange: (filters: BulkFilter[], update?: boolean) => void;
  hydrated: boolean;
  setHydrated: (value: boolean) => void;
  activeFilterIndex: number;
  setActiveFilterIndex: (value: number) => void;
  showFilters: boolean;
}

export interface BulkFilter {
  namespace?: {
    id: string;
  };
  schema?: {
    id: string;
    name: string;
  };
  field?: {
    id: string;
    name: string;
    type: FieldType;
  };
  function?: FilterFunction;
  value?: unknown;
}

export function BulkFilters({
  connectionId,
  initialFilters,
  namespaces,
  handleLoadFields,
  refetchSchema,
  bulkSyncSchemaLabel,
  filters,
  onChange,
  hydrated,
  setHydrated,
  activeFilterIndex,
  setActiveFilterIndex,
  showFilters
}: BulkFiltersProps) {
  const [hydrating, setHydrating] = useState<boolean>(false);

  const client = useApolloClient();

  const { getFilterFunctions, getFilterFunction } = useFilterFunctions();

  useEffect(() => {
    if (hydrated || hydrating) {
      return;
    }
    const toHydrate: Record<string, { namespace: IBulkNamespace; schema: IBulkSchema }> = {};

    if (initialFilters?.length) {
      setHydrating(true);
      namespaces?.forEach(namespace =>
        namespace?.schemas?.forEach(schema => {
          schema?.fields?.forEach(field => {
            if (
              initialFilters.find(
                filter => filter.fieldID === field.id && filter.schemaID === schema.id
              )
            ) {
              toHydrate[schema.id] = { namespace, schema };
            }
          });
        })
      );

      Promise.all(
        Object.values(toHydrate).map(
          schema =>
            new Promise<ApolloQueryResult<BulkSourceSchemaQuery>>(resolve => {
              const continuation = uuid();
              const interval = setInterval(async () => {
                const data = await client.query({
                  query: BulkSourceSchemaDocument,
                  variables: {
                    connectionId,
                    schemaId: schema.schema.id,
                    namespaceId: schema.namespace.id,
                    includeFields: true,
                    continuation
                  }
                });
                if (isContinuation(data) || isPoller(data)) {
                  return;
                }
                resolve(data);
                clearInterval(interval);
              }, 1000);
            })
        )
      ).then(sourceSchemas => {
        const hydrated = sourceSchemas?.map(sourceSchema => {
          const namespace = sourceSchema?.data?.bulkSourceForConnection?.namespaces?.[0];
          const schema = sourceSchema?.data?.bulkSourceForConnection?.namespaces?.[0]?.schemas?.[0];

          return initialFilters?.reduce<BulkFilter[]>((acc, filter) => {
            const field = schema.fields.find(field => field.id === filter.fieldID);
            if (field && schema.id === filter.schemaID) {
              acc.push({
                ...filter,
                namespace: {
                  id: namespace.id
                },
                schema,
                field
              });
            }
            return acc;
          }, []);
        });
        const newFilters = [...filters, ...hydrated.flat()];
        if ((newFilters?.length ?? 0) === (initialFilters?.length ?? 0)) {
          setHydrated(true);
        }
        setHydrating(false);
        onChange(newFilters);
      });
    } else {
      setHydrated(true);
    }
  }, [namespaces]);

  const isValid = (filter: BulkFilter) => {
    const filterFunction = getFilterFunction(filter?.field?.type, filter.function);
    return !!(filter.field && filter.function && (!filterFunction.requiresValue || filter.value));
  };

  const handleField = (field?: IBulkField) => {
    if (field) {
      const { namespace, schema } = getEntitiesFromPath(namespaces, field.path);
      const currentFilter = filters[activeFilterIndex];
      const newFilters = filters.map((filter, index) =>
        index === activeFilterIndex
          ? {
              function: currentFilter.field?.type === field.type ? currentFilter.function : null,
              value: currentFilter.field?.type === field.type ? currentFilter.value : null,
              field: { id: field.id, name: field.name, type: field.type },
              schema,
              namespace
            }
          : filter
      );
      onChange(newFilters, true);
    }
    setActiveFilterIndex(null);
  };

  const handleFunction = (option: SupportedFilterFunction, index: number) => {
    const currentFilter = filters[index];
    const newFilters = filters.map((filter, i) =>
      index === i
        ? {
            ...filter,
            function: option.id,
            value: currentFilter.function === option.id ? currentFilter.value : null
          }
        : filter
    );
    onChange(newFilters, true);
  };

  const handleValue = (value: any, index: number) => {
    const newFilters = filters.map((filter, i) => (i === index ? { ...filter, value } : filter));
    onChange(newFilters, true);
  };

  const activeFilter = filters[activeFilterIndex];
  const selectedPath = activeFilter
    ? getPathFromEntities(activeFilter?.namespace, activeFilter?.schema, activeFilter?.field)
    : null;

  return (
    <>
      {(!!filters?.length || !!initialFilters?.length || showFilters) && (
        <div className="border-t pt-4">
          <label className="block pb-2 text-sm font-semibold">Table filters</label>
          {!hydrated && (
            <div className="flex w-full justify-center">
              <LoadingDots />
            </div>
          )}
          {hydrated && (
            <div className="flex w-full flex-col gap-2">
              {!filters?.length && (
                <ParamButton
                  action="add"
                  onClick={() => {
                    onChange([{}]);
                  }}
                />
              )}
              {filters.map((filter, index) => {
                const functionOptions = getFilterFunctions(filter?.field?.type);
                const filterFunction = functionOptions?.find(func => func.id === filter.function);

                return (
                  <div key={filter?.field?.id ?? 'New field'} className="w-full">
                    <label className="thead-text block text-gray-500">
                      {index === 0 ? 'WHERE' : 'AND'}
                    </label>
                    <div className="flex w-full gap-2">
                      <Tooltip
                        content={
                          <div className="flex items-center">
                            {filter?.namespace?.id && (
                              <>
                                <span>{filter.namespace.id}</span>
                                <Icon name="Disclosure" />
                              </>
                            )}
                            {filter?.schema?.name && (
                              <>
                                <span>{filter.schema.name}</span>
                                <Icon name="Disclosure" />
                              </>
                            )}
                            <span>{filter.field?.name}</span>
                          </div>
                        }
                        disabled={!filter?.schema?.name || !filter?.field?.name}
                      >
                        <button
                          className={cn(
                            'flex h-8 flex-1 items-center gap-1 truncate rounded bg-gray-50 py-1 px-4 text-left hover:bg-indigo-50',
                            !filter?.field?.id && 'text-gray-500',
                            activeFilterIndex === index && 'bg-indigo-50'
                          )}
                          onClick={() => setActiveFilterIndex(index)}
                        >
                          {filter?.schema?.id && (
                            <>
                              <span className="min-w-[50px] max-w-fit shrink-0 grow basis-0 truncate text-gray-500">
                                {filter.schema.name}
                              </span>
                              <Icon name="Disclosure" className="text-gray-500" />
                            </>
                          )}
                          <span className="max-w-fit grow truncate">
                            {filter?.field?.name ?? 'Pick field...'}
                          </span>
                        </button>
                      </Tooltip>
                      <div className="w-[200px]">
                        {filter?.field?.id && (
                          <MyCombobox
                            className="w-full"
                            variant="flat"
                            options={toSelectables(functionOptions)}
                            value={filterFunction ? toSelectable(filterFunction) : null}
                            onChange={option => handleFunction(option, index)}
                            isDisabled={!filter?.field.id}
                          />
                        )}
                      </div>
                      <div className="w-[200px]">
                        {filterFunction?.requiresValue && (
                          <FilterValueElements
                            variant="flat"
                            value={filter.value}
                            fieldType={filter.field.type}
                            filterFunction={filterFunction}
                            handleValue={value => handleValue(value, index)}
                          />
                        )}
                      </div>
                      <div className="pt-1.5">
                        <RowControl
                          index={index}
                          total={filters?.length}
                          canAdd={index === filters.length - 1 && isValid(filter)}
                          onAdd={() => {
                            const newFilters = [
                              ...filters.slice(0, index + 1),
                              {},
                              ...filters.slice(index + 1)
                            ];
                            onChange(newFilters, true);
                          }}
                          onDelete={() => {
                            const newFilters = filters.filter((f, i) => i !== index);
                            onChange(newFilters, true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <BulkFieldPickerDrawer
        open={activeFilterIndex !== null}
        namespaces={namespaces}
        connectionId={connectionId}
        refetchSchema={refetchSchema}
        handleLoadFields={handleLoadFields}
        onDismiss={handleField}
        bulkSyncSchemaLabel={bulkSyncSchemaLabel}
        selectedPath={selectedPath}
      />
    </>
  );
}

import { ConnectionTypeFragment } from '../../../generated/graphql';
import { EnrichmentAuthentication } from './enrichment/EnrichmentAuthentication';
import { EnrichmentFields } from './enrichment/EnrichmentFields';
import { EnrichmentRequest } from './enrichment/EnrichmentRequest';
import { EnrichmentTest } from './enrichment/EnrichmentTest';

export type EnrichmentConnectionConfigProps = {
  connectionType: ConnectionTypeFragment;
};

export function EnrichmentConnectionConfig({ connectionType }: EnrichmentConnectionConfigProps) {
  return (
    <>
      <EnrichmentAuthentication connectionType={connectionType} />
      <EnrichmentRequest connectionType={connectionType} />
      <EnrichmentTest />
      <EnrichmentFields />
    </>
  );
}

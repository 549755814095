import { functionalUpdate, RowSelectionState } from '@tanstack/react-table';
import { keyBy, mapValues } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Select, { components, createFilter, SingleValueProps } from 'react-select';
import { IconName } from '~/assets';
import {
  DisabledSelect,
  EditPermission,
  Icon,
  MyInput,
  MyPopover,
  PopoverAction,
  PopoverButtonMinimal,
  Tooltip,
  Truncator
} from '~/components';
import { ColumnDef, DataTable } from '~/components/v3';
import { FieldType, ModelFieldFragment } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import {
  DropdownIndicator,
  fieldTypeIconName,
  getValueAsFormattedString,
  selectStyles
} from '~/utils';

function formatOptionLabel({ value, label }: { value: FieldType; label: IconName }) {
  return (
    <span className="flex space-x-2">
      <Icon name={label} className="h-5 w-5 text-gray-500" />
      <span>{value}</span>
    </span>
  );
}

function SingleValue(props: SingleValueProps<{ value: FieldType; label: IconName }>) {
  return (
    <components.SingleValue {...props}>
      <span className="flex space-x-2">
        <Icon name={props.data.label} className="h-5 w-5 text-gray-500" />
        <span>{props.data.value || 'Not specified'}</span>
      </span>
    </components.SingleValue>
  );
}

interface FieldTypeSelectProps {
  field: ModelFieldFragment;
  onFieldChange: (field: ModelFieldFragment) => void;
}

const FieldTypeSelect = ({ field, onFieldChange }: FieldTypeSelectProps) => {
  return (
    <EditPermission
      fallback={
        <DisabledSelect
          valueLabel={
            <>
              <Icon name={fieldTypeIconName(field?.type)} className="h-5 w-5 text-gray-500" />
              <div className="ml-1.5 text-sm text-gray-800">{field?.type}</div>
            </>
          }
          className="w-full min-w-[130px]"
        />
      }
    >
      <Select
        autoFocus={false}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          SingleValue,
          DropdownIndicator,
          ClearIndicator: null,
          IndicatorSeparator: null
        }}
        formatOptionLabel={formatOptionLabel}
        value={field.type ? { value: field.type, label: fieldTypeIconName(field.type) } : null}
        options={Object.values(FieldType)
          .map(type => ({
            value: type,
            label: fieldTypeIconName(type)
          }))
          .filter(({ value }) => value !== FieldType.Unknown)}
        onChange={(option: { label: IconName; value: FieldType } | null) => {
          if (!option) {
            return;
          }
          onFieldChange({ ...field, type: option.value });
        }}
        maxMenuHeight={256}
        className="w-full min-w-[130px]"
        styles={selectStyles}
        menuShouldBlockScroll
        menuPortalTarget={document.body}
        menuPosition="fixed"
        menuPlacement="bottom"
      />
    </EditPermission>
  );
};

interface ActionsPopoverProps {
  field: ModelFieldFragment;
  onFieldDelete: (field: ModelFieldFragment) => void;
}

const ActionsPopover = ({ field, onFieldDelete }: ActionsPopoverProps) => {
  const [show, toggle] = useToggle();

  return (
    <div className="pt-1.5">
      <MyPopover
        visible={show}
        onClickOutside={toggle}
        placement="top-end"
        offset={[0, 6]}
        className="z-10 w-52"
        content={
          <EditPermission>
            <PopoverAction onClick={() => onFieldDelete(field)}>Delete field</PopoverAction>
          </EditPermission>
        }
      >
        <PopoverButtonMinimal onClick={toggle} isShowingPopover={show} />
      </MyPopover>
    </div>
  );
};

interface FieldsPickerProps {
  fields: ModelFieldFragment[];
  onFieldsChange: (fields: ModelFieldFragment[]) => void;
  hasWriteinFields?: boolean;
  userTypeSelection?: boolean;
  loading?: boolean;
  disabled?: boolean;
  showSourceIcon?: boolean;
  search?: string;
  emptyMessage?: string;
  disableRowSelection?: boolean;
  hideExampleColumn?: boolean;
}

export const FieldsPicker = ({
  fields: defaultFields,
  onFieldsChange,
  hasWriteinFields,
  userTypeSelection,
  loading,
  disabled,
  showSourceIcon,
  search,
  emptyMessage,
  disableRowSelection,
  hideExampleColumn
}: FieldsPickerProps) => {
  const [fields, setFields] = useState(defaultFields);

  const queryParameters = new URLSearchParams(location.search);
  const [field, setField] = useState<string>(queryParameters.get('field'));

  useEffect(() => {
    setTimeout(() => {
      setField(null);
    }, 2000);
  }, []);

  useEffect(() => {
    setFields(defaultFields);
    setRowSelection(
      mapValues(
        keyBy(
          defaultFields.filter(f => f.published),
          'id'
        ),
        () => true
      )
    );
  }, [defaultFields]);

  const handleFieldDelete = (field: ModelFieldFragment) => {
    const newFields = fields.filter(f => f.id !== field.id);
    setFields(newFields);
    onFieldsChange(newFields);
  };

  const handleFieldChange = (field: ModelFieldFragment) => {
    const newFields = fields.map(f => (f.id === field.id ? field : f));
    setFields(newFields);
    onFieldsChange(newFields);
  };

  const handleRowSelectionChange = (selection: RowSelectionState) => {
    const newFields = fields.map(f => ({ ...f, published: !!selection?.[f.id] }));
    setRowSelection(selection);
    setFields(newFields);
    onFieldsChange(newFields);
  };

  const columns = useMemo<ColumnDef<ModelFieldFragment>[]>(() => {
    const cols: ColumnDef<ModelFieldFragment>[] = [
      {
        header: () => <span>Label</span>,
        accessorKey: 'label',
        cell: ({ row }) => {
          const [value, setValue] = useState(row.original.label);
          return (
            <EditPermission>
              <MyInput
                theme="table"
                value={value}
                onChange={e => setValue(e.target.value)}
                disabled={disabled}
                onBlur={() => {
                  if (row.original.label !== value) {
                    handleFieldChange({ ...row.original, label: value });
                  }
                }}
                style={{ maxWidth: '275px' }}
              />
            </EditPermission>
          );
        },
        size: 275
      },
      {
        header: 'Example',
        accessorKey: 'example',
        cell: ({ row }) => <TableTruncatedCell value={row.original.example} />,
        size: 200,
        isVisible: !hideExampleColumn
      },
      {
        header: 'Type',
        accessorKey: 'type',
        cell: ({ row }) =>
          userTypeSelection ? (
            <FieldTypeSelect field={row.original} onFieldChange={handleFieldChange} />
          ) : (
            <Tooltip
              offset={[0, 2]}
              disabled={!row.original.sourceType}
              content={row.original.sourceType}
            >
              <div className="flex items-center space-x-1.5 py-1.5">
                <Icon
                  name={fieldTypeIconName(row.original.type)}
                  className="h-5 w-5 text-gray-500"
                />
                <span className="text-gray-800">{row.original.type || 'unknown'}</span>
              </div>
            </Tooltip>
          ),
        size: 150
      },
      {
        header: 'Source',
        accessorKey: 'sourceName',
        cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            {!!showSourceIcon && <Icon match={row.original.fieldset.connection.type.id} />}
            <TableTruncatedCell value={row.original.sourceName} />
          </div>
        ),
        size: 150
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: ({ row }) =>
          row.original.userAdded ? (
            <ActionsPopover field={row.original} onFieldDelete={handleFieldDelete} />
          ) : null,
        size: 50,
        isVisible: hasWriteinFields
      }
    ];
    return cols;
  }, [hasWriteinFields, handleFieldChange, handleFieldDelete, disabled]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    mapValues(
      keyBy(
        fields.filter(f => f.published),
        'id'
      ),
      () => true
    )
  );

  return (
    <DataTable
      columns={columns}
      data={fields}
      getRowId={row => row.id}
      loading={loading}
      // Search
      globalFilter={search}
      onGlobalFilterChange={() => {}}
      // Selection
      rowSelection={rowSelection}
      onRowSelectionChange={updaterFunction =>
        handleRowSelectionChange(functionalUpdate(updaterFunction, rowSelection))
      }
      disableRowSelection={disableRowSelection}
      classNames={{ wrapper: 'max-h-[37.5rem] border-none', expander: 'overflow-visible' }}
      showLoadingWhenRowsExist={true}
      lockHeight={true}
      estimateSize={() => 64}
      scrollToIndex={field && fields.findIndex(f => f.id === field)}
      isRowActive={row => row.id === field}
      emptyMessage={emptyMessage}
    />
  );
};

function TableTruncatedCell({ value }: { value: string }) {
  const val = getValueAsFormattedString(value);
  return (
    <Truncator
      offset={[0, 4]}
      className="w-max max-w-lg"
      placement="auto"
      content={
        val ? (
          <pre className="overflow-hidden text-xs font-normal leading-4">{val}</pre>
        ) : (
          <span className="break-words text-sm leading-5">{String(value)}</span>
        )
      }
    >
      <p className="hide-native-tooltip w-full max-w-[15rem] cursor-default truncate py-1.5 text-sm text-gray-800">
        {val}
      </p>
    </Truncator>
  );
}

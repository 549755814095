import {
  DisabledSelect,
  EditPermission,
  FormArray,
  MyCombobox,
  MyInput,
  SideBySide
} from '~/components';
import { CONNECTION_AUTH_METHODS } from '~/components/v2/configs/SelectOptions';
import { ConnectionFormValues } from '../../connection-config';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { getSchemaAsList, getSchemaNormalized, isRequiredMsg, Selectable } from '~/utils';
import { ConnectionTypeFragment } from '~/generated/graphql';

interface EnrichmentAuthenticationProps {
  connectionType: ConnectionTypeFragment;
}

// This is mostly copied from API config
export function EnrichmentAuthentication({ connectionType }: EnrichmentAuthenticationProps) {
  const [inputType, setInputType] = useState<'password' | 'text'>('password');
  const { setValue, register, unregister, getValues, formState } =
    useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMethod] = useState<Selectable>(() => {
    const auth = getValues('configuration.auth') as Record<string, string>;
    if (!auth) {
      return CONNECTION_AUTH_METHODS.NONE;
    }
    if (auth.basic) {
      return CONNECTION_AUTH_METHODS.BASIC;
    }
    if (auth.header) {
      return CONNECTION_AUTH_METHODS.HEADER;
    }
    if (auth.oauth) {
      return CONNECTION_AUTH_METHODS.OAUTH;
    }
    return CONNECTION_AUTH_METHODS.NONE;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);
  const list = getSchemaAsList(connectionType.configurationSchema, 'configuration');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
  const extraFormConfig = list
    ?.find(({ name }) => name === 'configuration.auth')
    ?.schema?.find(({ name }: { name: string }) => name === 'configuration.auth.oauth')
    ?.schema?.find(
      ({ name }: { name: string }) => name === 'configuration.auth.oauth.extra_form_data'
    );
  // TODO: this is a hack to clear the auth field  - find a better way
  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      unregister('configuration.auth');
      if (option.value === 'none') {
        setValue('configuration.auth', null, {
          shouldValidate: true,
          shouldDirty: true
        });
      }
      setAuthMethod(option);
    }
  };

  return (
    <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
      <EditPermission
        fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
      >
        <MyCombobox
          className="max-w-xs"
          value={authMethod}
          options={Object.values(CONNECTION_AUTH_METHODS).filter(
            m => m.value !== CONNECTION_AUTH_METHODS.OAUTH.value
          )}
          onChange={handleAuthMethodChange}
        />
      </EditPermission>
      {authMethod?.value === CONNECTION_AUTH_METHODS.BASIC.value && (
        <>
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.auth.basic.username', {
                  required: isRequiredMsg(fields?.['auth.basic.username']?.title)
                })}
                label={fields?.['auth.basic.username']?.title}
                errors={errors}
              />
            </EditPermission>
          </div>
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.auth.basic.password', {
                  required: isRequiredMsg(fields?.['auth.basic.password']?.title)
                })}
                type={inputType}
                onMouseEnter={() => setInputType('text')}
                onMouseLeave={() => setInputType('password')}
                label={fields?.['auth.basic.password']?.title}
                errors={errors}
              />
            </EditPermission>
          </div>
        </>
      )}
      {authMethod?.value === CONNECTION_AUTH_METHODS.HEADER.value && (
        <>
          <div className="grid grid-cols-[8rem,1fr] gap-2">
            <div>
              <EditPermission>
                <MyInput
                  {...register('configuration.auth.header.name', {
                    required: isRequiredMsg(fields?.['auth.header.name']?.title)
                  })}
                  label={fields?.['auth.header.name']?.title}
                  errors={errors}
                />
              </EditPermission>
            </div>
            <div>
              <EditPermission>
                <MyInput
                  {...register('configuration.auth.header.value', {
                    required: isRequiredMsg(fields?.['auth.header.value']?.title)
                  })}
                  label={fields?.['auth.header.value']?.title}
                  errors={errors}
                />
              </EditPermission>
            </div>
          </div>
        </>
      )}
      {authMethod?.value === CONNECTION_AUTH_METHODS.OAUTH.value && (
        <>
          <div>
            <EditPermission>
              <MyInput
                {...register('configuration.auth.oauth.client_id', {
                  required: isRequiredMsg(fields?.['auth.oauth.client_id']?.title)
                })}
                label={fields?.['auth.oauth.client_id']?.title}
                errors={errors}
              />
            </EditPermission>
          </div>
          <div>
            <EditPermission>
              <MyInput
                {...register('configuration.auth.oauth.client_secret', {
                  required: isRequiredMsg(fields?.['auth.oauth.client_secret']?.title)
                })}
                type="password"
                label={fields?.['auth.oauth.client_secret']?.title}
                errors={errors}
              />
            </EditPermission>
          </div>
          <div>
            <EditPermission>
              <MyInput
                {...register('configuration.auth.oauth.token_endpoint', {
                  required: isRequiredMsg(fields?.['auth.oauth.token_endpoint']?.title)
                })}
                label={fields?.['auth.oauth.token_endpoint']?.title}
                errors={errors}
              />
            </EditPermission>
          </div>
          {extraFormConfig && (
            <FormArray
              className="grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              item={extraFormConfig}
            />
          )}
        </>
      )}
    </SideBySide>
  );
}

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { findKey, omit } from 'lodash';
import {
  DisabledSelect,
  EditPermission,
  FormBuilder,
  JSONSchemaForm,
  MyCombobox,
  SideBySide
} from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { Selectable } from '../../../utils';
import { OauthButton } from '../connection-components';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';

const AZURE_BLOB_AUTH_METHODS = {
  ACCESS_KEY: {
    value: 'access_key',
    label: 'Access Key'
  },
  CLIENT_CREDENTIALS: {
    value: 'client_credentials',
    label: 'Client Credentials'
  },
  OAUTH: { label: 'OAuth', value: 'oauth' }
};

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
  fetchOptions?: (field: string, query?: string) => Promise<Selectable[]>;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function AzureBlobConnectionConfig(props: Props) {
  const {
    connection,
    connectionType,
    features,
    oauthLoading,
    saved,
    promiseOptions,
    fetchOptions,
    onSave,
    setConnectionType
  } = props;

  const { setValue, getValues, watch } = useFormContext();

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_method');
    return (
      AZURE_BLOB_AUTH_METHODS[findKey(AZURE_BLOB_AUTH_METHODS, k => k.value === auth)] ??
      AZURE_BLOB_AUTH_METHODS.ACCESS_KEY
    );
  });

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_method', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
      setConnectionType({ ...connectionType, useOAuth: option.value === 'oauth' });
    }
  };

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(AZURE_BLOB_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
        <div className="max-w-xs space-y-3">
          {authMethod?.value === AZURE_BLOB_AUTH_METHODS.ACCESS_KEY.value && (
            <FormBuilder<ConnectionFormValues>
              features={features}
              schema={omit(props.connectionType.configurationSchema, [
                'definitions.ConnectionConf.properties.auth_method',
                'definitions.ConnectionConf.properties.account_name',
                //   'definitions.ConnectionConf.properties.access_key',
                'definitions.ConnectionConf.properties.tenant_id',
                'definitions.ConnectionConf.properties.client_id',
                'definitions.ConnectionConf.properties.client_secret',
                'definitions.ConnectionConf.properties.container_name',
                'definitions.ConnectionConf.properties.is_single_table',
                'definitions.ConnectionConf.properties.single_table_name',
                'definitions.ConnectionConf.properties.single_table_file_format',
                'definitions.ConnectionConf.properties.skip_lines'
              ])}
              prefix="configuration"
              promiseOptions={promiseOptions}
            />
          )}
          {authMethod?.value === AZURE_BLOB_AUTH_METHODS.CLIENT_CREDENTIALS.value && (
            <FormBuilder<ConnectionFormValues>
              features={features}
              schema={omit(props.connectionType.configurationSchema, [
                'definitions.ConnectionConf.properties.auth_method',
                'definitions.ConnectionConf.properties.account_name',
                'definitions.ConnectionConf.properties.access_key',
                //   'definitions.ConnectionConf.properties.tenant_id',
                //   'definitions.ConnectionConf.properties.client_id',
                //   'definitions.ConnectionConf.properties.client_secret',
                'definitions.ConnectionConf.properties.container_name',
                'definitions.ConnectionConf.properties.is_single_table',
                'definitions.ConnectionConf.properties.single_table_name',
                'definitions.ConnectionConf.properties.single_table_file_format',
                'definitions.ConnectionConf.properties.skip_lines'
              ])}
              prefix="configuration"
              promiseOptions={promiseOptions}
            />
          )}
          {authMethod?.value === AZURE_BLOB_AUTH_METHODS.OAUTH.value && (
            <OauthButton
              isConnected={
                saved ||
                (!!connection?.saved && authMethod?.value === AZURE_BLOB_AUTH_METHODS.OAUTH.value)
              }
              oauthLoading={oauthLoading}
              connectionTypeName={connectionType.name}
              onSave={onSave}
            />
          )}
          <JSONSchemaForm<ConnectionFormValues>
            schema={{
              ...omit(connectionType.configurationForm.jsonschema, [
                'properties.auth_method',
                'properties.oauth_refresh_token',
                'dependentSchemas.auth_method'
              ]),
              required: ['container_name']
            }}
            formData={watch('configuration') || connectionType.initialConfiguration}
            uiSchema={connectionType.configurationForm.uischema}
            onChange={value => {
              setValue('configuration', value, { shouldDirty: true });
            }}
            fetchOptions={fetchOptions}
          />
        </div>
      </SideBySide>
    </>
  );
}

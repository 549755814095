import { Row } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Icon, MyInput, Search } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import {
  BulkDestinationVocabulary,
  BulkSourceSchemaDocument,
  BulkSourceSchemaQuery,
  BulkSourceSchemaQueryVariables,
  BulkSyncFragment
} from '~/generated/graphql';
import { useAuth, useLazyContinuationQuery, useToggle } from '~/hooks';
import { cn } from '~/lib/utils';
import { IBulkField, IBulkSchema, SchemaOrFieldKey } from '../components/BulkNamespaceUtil';
import { Dialog } from '~/components/v3';

interface SchemaRowMenuProps {
  row: Row<IBulkSchema>;
  connectionId: string;
  supportsPartitionKeys: boolean;
  supportsTrackingFields: boolean;
  setNamespaceValue: (path: string, key: SchemaOrFieldKey, value: string) => void;
  vocabulary: BulkDestinationVocabulary;
  bulkSync: BulkSyncFragment;
}

export function SchemaRowMenu({
  row,
  setNamespaceValue,
  connectionId,
  supportsPartitionKeys,
  supportsTrackingFields,
  vocabulary,
  bulkSync
}: SchemaRowMenuProps) {
  const { user } = useAuth();

  const schema = row.original as IBulkSchema;

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<Partial<IBulkField>[]>(schema?.fields ?? []);
  const [showDialog, toggleDialog] = useToggle();

  const handleOutputName = () => {
    if (!isEmpty(schema.userOutputName)) {
      setNamespaceValue(schema.path, 'userOutputName', null);
    } else {
      toggleDialog();
    }
  };

  const [loadSchemaFields] = useLazyContinuationQuery<
    BulkSourceSchemaQuery,
    BulkSourceSchemaQueryVariables
  >(BulkSourceSchemaDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFields(data?.bulkSourceForConnection?.namespaces?.[0]?.schemas?.[0]?.fields ?? []);
    }
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadSchemaFields({
        variables: {
          connectionId: connectionId,
          namespaceId: row.getParentRow()?.id,
          schemaId: schema.id,
          continuation: uuid(),
          includeFields: true
        }
      });
      setLoading(false);
    };

    if (open && !isEmpty(schema.selectionState)) {
      load();
    }
  }, [open]);

  const [trackingFieldSearch, setTrackingFieldSearch] = useState<string>('');
  const [partitionKeySearch, setPartitionKeySearch] = useState<string>('');

  const trackingFieldOptions = useMemo(
    () =>
      fields
        .filter(field => field.trackable)
        .filter(field => field.name?.toLowerCase().includes(trackingFieldSearch?.toLowerCase())),
    [fields, trackingFieldSearch]
  );

  const partitionKeyOptions = useMemo(
    () =>
      fields
        .filter(field => field.partitionable)
        .filter(field => field.name?.toLowerCase().includes(partitionKeySearch?.toLowerCase())),
    [fields, partitionKeySearch]
  );

  const partitionKeyLabel = !isEmpty(vocabulary.partitionKey)
    ? vocabulary.partitionKey
    : 'Partition key';

  const userOutputNames =
    user.organization?.features?.includes('bulk-sync-user-output-names') ||
    bulkSync?.features?.includes('bulk-sync-user-output-names');

  return (
    <>
      <div className={cn('flex flex-row items-start justify-end space-x-2 text-gray-500')}>
        {!!schema.partitionKey && (
          <TooltipIcon
            message={`${partitionKeyLabel}: ${schema.partitionKey}`}
            icon={<Icon name="PartitionKey" size="sm" className="align-top" />}
          />
        )}
        {!!schema.userOutputName && userOutputNames && (
          <TooltipIcon
            message={`Custom output name: ${schema.userOutputName}`}
            icon={<Icon name="TypeString" size="sm" className={'align-top'} />}
          />
        )}
        {(supportsPartitionKeys || supportsTrackingFields || userOutputNames) && (
          <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
              <div className="invisible rounded p-[2px] hover:bg-gray-300 group-hover/row:visible">
                <Icon name="DotsH" size="sm" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" portal={false} onClick={e => e.stopPropagation()}>
              {!!supportsPartitionKeys && (
                <DropdownMenuSub onOpenChange={() => setPartitionKeySearch('')}>
                  <DropdownMenuSubTrigger>{partitionKeyLabel}</DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent onClick={e => e.stopPropagation()}>
                      <DropdownMenuLabel>{partitionKeyLabel}</DropdownMenuLabel>
                      <div className="mb-2 px-2">
                        <Search
                          className="font-normal"
                          wrapperStyles="h-8 w-80"
                          placeholder="Search..."
                          defaultValue={partitionKeySearch}
                          onChange={setPartitionKeySearch}
                          onReset={() => setPartitionKeySearch('')}
                        />
                      </div>
                      {loading && <LoadingDots />}
                      {!loading && (
                        <DropdownMenuRadioGroup
                          value={schema.partitionKey}
                          onValueChange={v =>
                            setNamespaceValue(row.original.path, 'partitionKey', v)
                          }
                        >
                          <DropdownMenuRadioItem value="" checked={!schema.partitionKey}>
                            None
                          </DropdownMenuRadioItem>
                          {partitionKeyOptions.map(field => (
                            <DropdownMenuRadioItem
                              key={field.id}
                              value={field.id}
                              checked={schema.partitionKey === field.id}
                            >
                              {field.name}
                            </DropdownMenuRadioItem>
                          ))}
                        </DropdownMenuRadioGroup>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              )}
              {!!supportsTrackingFields && (
                <DropdownMenuSub onOpenChange={() => setTrackingFieldSearch('')}>
                  <DropdownMenuSubTrigger>Tracking field</DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent onClick={e => e.stopPropagation()}>
                      <DropdownMenuLabel>Tracking field</DropdownMenuLabel>
                      <div className="mb-2 px-2">
                        <Search
                          className="font-normal"
                          wrapperStyles="h-8 w-80"
                          placeholder="Search..."
                          defaultValue={trackingFieldSearch}
                          onChange={setTrackingFieldSearch}
                          onReset={() => setTrackingFieldSearch('')}
                        />
                      </div>
                      {loading && <LoadingDots />}
                      {!loading && (
                        <DropdownMenuRadioGroup
                          value={schema.trackingField}
                          onValueChange={v =>
                            setNamespaceValue(row.original.path, 'trackingField', v)
                          }
                        >
                          <DropdownMenuRadioItem value="" checked={!schema.trackingField}>
                            None
                          </DropdownMenuRadioItem>
                          {trackingFieldOptions.map(field => (
                            <DropdownMenuRadioItem
                              key={field.id}
                              value={field.id}
                              checked={schema.trackingField === field.id}
                            >
                              {field.name}
                            </DropdownMenuRadioItem>
                          ))}
                        </DropdownMenuRadioGroup>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              )}
              {userOutputNames && (
                <>
                  {(supportsPartitionKeys || supportsTrackingFields) && <DropdownMenuSeparator />}
                  <DropdownMenuItem onClick={handleOutputName} className="flex items-center gap-1">
                    <Icon name="TypeString" />
                    {isEmpty(schema.userOutputName) ? (
                      <span>Set custom output name</span>
                    ) : (
                      <span>Remove custom output name</span>
                    )}
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      <CustomOutputNameDialog
        name={schema.userOutputName}
        show={showDialog}
        onDismiss={name => {
          if (!isEmpty(name)) {
            setNamespaceValue(schema.path, 'userOutputName', name);
          }
          toggleDialog();
        }}
      />
    </>
  );
}

interface CustomOutputNameDialogProps {
  show: boolean;
  onDismiss: (name?: string) => void;
  name?: string;
}

function CustomOutputNameDialog({ show, onDismiss, name: _name }: CustomOutputNameDialogProps) {
  const [name, setName] = useState<string>(_name);

  return (
    <Dialog
      show={show}
      onDismiss={onDismiss}
      heading="Set custom column output name"
      actions={
        <>
          <Button onClick={() => onDismiss()}>Cancel</Button>
          <Button theme="primary" disabled={isEmpty(name)} onClick={() => onDismiss(name)}>
            OK
          </Button>
        </>
      }
    >
      <div className="flex flex-col gap-2">
        <p>Specify a column name to override automatic naming</p>
        <MyInput
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Column name"
          onKeyDown={e => {
            if (e.key === 'Enter' && !isEmpty(name)) {
              onDismiss(name);
            }
          }}
        />
      </div>
    </Dialog>
  );
}

import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from './Command';
import { Button } from '../form-components';
import { Icon } from '../Icon';
import { cn } from '~/lib/utils';

interface Option {
  label: string;
  value: any;
  icon?: string;
}

interface ComboboxProps<T extends Option> {
  options: T[];
  value: T;
  onChange: (option: T) => void;
  placeholder?: string;
}

export function Combobox<T extends Option>({
  options,
  value,
  onChange,
  placeholder = 'Select...'
}: ComboboxProps<T>) {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          //variant="outline"
          theme="ghost"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'w-full justify-between rounded-none !bg-white font-normal text-gray-700 hover:!bg-gray-50',
            !value?.label && 'text-gray-400'
          )}
        >
          <div className="flex gap-2">
            {!!value?.icon && <Icon className="text-gray-500" match={value.icon} />}
            <span>{value?.label ?? placeholder}</span>
          </div>
          <Icon name="Select" className=" text-gray-700 opacity-70 group-hover:opacity-100" />
          {/* <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width] p-0"
        style={{ width: 'var(--radix-popover-trigger-width)' }}
      >
        <Command>
          {/* <CommandInput placeholder="Search framework..." /> */}
          <CommandList>
            <CommandEmpty>No results.</CommandEmpty>
            <CommandGroup>
              {options.map(option => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={value => {
                    onChange(options.find(option => option.value == value));
                    setOpen(false);
                  }}
                  className="flex gap-2"
                >
                  {!!option.icon && <Icon className="text-gray-500" match={option.icon} />}
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

import { useEffect, useMemo } from 'react';
import {
  Editor,
  EditPermission,
  FormArray,
  Label,
  MyCombobox,
  MyInput,
  SideBySide
} from '~/components';
import { ConnectionFormValues } from '../../connection-config';
import { useFormContext } from 'react-hook-form';
import { Table, TableBody, TableCell, TableRow } from '~/components/v3/Table';
import {
  fieldTypeIconName,
  findName,
  getSchemaAsList,
  getSchemaNormalized,
  isJsonString,
  isRequiredMsg,
  json
} from '~/utils';
import { capitalize, isEmpty } from 'lodash';
import { Combobox } from '~/components/v3';
import { RowControl } from '~/components/v3/RowControl';
import { ConnectionTypeFragment, FieldType } from '~/generated/graphql';

interface EnrichmentInput {
  name?: string;
  type?: FieldType;
  required?: boolean;
}

const requestMethods = {
  GET: {
    label: 'GET',
    value: 'GET'
  },
  POST: {
    label: 'POST',
    value: 'POST'
  }
};

const requiredOptions = {
  OPTIONAL: {
    label: 'Optional',
    value: 'optional'
  },
  REQUIRED: {
    label: 'Required',
    value: 'required'
  }
};

interface EnrichmentRequestProps {
  connectionType: ConnectionTypeFragment;
}

export function EnrichmentRequest({ connectionType }: EnrichmentRequestProps) {
  const { setValue, register, formState, watch } = useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const inputMappings = watch('configuration.inputMappings') as EnrichmentInput[];
  const method = watch('configuration.method') as string;
  const body = watch('configuration.body') as string;

  const fields = getSchemaNormalized(connectionType.configurationSchema);
  const list = getSchemaAsList(connectionType.configurationSchema, 'configuration');
  const headersConfig = findName(list, 'configuration.headers');
  const parametersConfig = findName(list, 'configuration.parameters');

  const jsonError = useMemo(() => {
    if (!body) {
      return null;
    }
    const regExp = new RegExp(`{{(.+?)}}`, 'g');
    const matches = [...body.matchAll(regExp)];
    let err;
    let str = body;
    matches?.forEach(match => {
      const found = inputMappings.find(input => input.name === match[1].trim());
      if (!found) {
        err = `Undefined variable ${match[1]}`;
      }
      if (found?.type !== FieldType.String) {
        str = str.replace(match[0], '""');
      }
    });
    if (err) {
      return err;
    }
    if (!isEmpty(str) && !isJsonString(str)) {
      return 'Invalid JSON';
    }
    return null;
  }, [body, inputMappings]);

  useEffect(() => {
    if (!inputMappings?.length) {
      setTimeout(() => {
        setValue('configuration.inputMappings', [
          { name: '', type: FieldType.String, required: true }
        ]);
      });
    }
  }, []);

  return (
    <SideBySide hasSectionWrap styles="space-y-3" heading="Enrichment request">
      <div>
        <Label>Request URL</Label>
        <EditPermission>
          <div className="flex flex-row items-center gap-1">
            <MyCombobox
              defaultValue={requestMethods.GET}
              className="w-[80px]"
              options={Object.values(requestMethods)}
              onChange={option => {
                setValue('configuration.method', option.value);
              }}
              value={requestMethods[method ?? 'GET']}
            />
            <MyInput
              {...register('configuration.url', {
                required: isRequiredMsg(fields?.['url']?.title),
                pattern: {
                  value:
                    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                  message: 'Invalid URL'
                }
              })}
              description={fields?.['url']?.description}
              errors={errors}
              className="flex-1"
              placeholder="https://"
            />
          </div>
        </EditPermission>
      </div>

      <div>
        <Label>Input variables</Label>
        {!!inputMappings?.length && (
          <Table className="inset-1 rounded">
            <TableBody>
              {inputMappings.map((input, index) => (
                <TableRow key={index} className="rounded ">
                  <TableCell className="rounded border p-0">
                    <MyInput
                      placeholder="Variable name"
                      value={input.name}
                      variant="flat"
                      className="rounded-none border bg-white focus:ring-0"
                      onChange={e =>
                        setValue(
                          'configuration.inputMappings',
                          inputMappings.map((input, i) =>
                            i === index ? { ...input, name: e.target.value } : input
                          )
                        )
                      }
                    />
                  </TableCell>
                  <TableCell className="w-[125px] rounded border p-0">
                    <Combobox
                      aria-label="Field type"
                      options={Object.values(FieldType)
                        .map(type => ({
                          value: type,
                          label: capitalize(type),
                          icon: fieldTypeIconName(type)
                        }))
                        .filter(({ value }) => value !== FieldType.Unknown)}
                      value={
                        input.type
                          ? {
                              label: capitalize(input.type),
                              value: input.type,
                              icon: fieldTypeIconName(input.type)
                            }
                          : null
                      }
                      onChange={option =>
                        setValue(
                          'configuration.inputMappings',
                          inputMappings.map((input, i) =>
                            i === index ? { ...input, type: option.value } : input
                          )
                        )
                      }
                      placeholder="Type"
                    />
                  </TableCell>
                  <TableCell className="w-[100px] border p-0">
                    <Combobox
                      options={Object.values(requiredOptions)}
                      onChange={option => {
                        setValue(
                          'configuration.inputMappings',
                          inputMappings.map((input, i) =>
                            i === index
                              ? {
                                  ...input,
                                  required: option.value === requiredOptions.REQUIRED.value
                                }
                              : input
                          )
                        );
                      }}
                      value={input.required ? requiredOptions.REQUIRED : requiredOptions.OPTIONAL}
                    />
                  </TableCell>
                  <TableCell className="w-[60px] px-2 py-0">
                    <RowControl
                      index={index}
                      total={inputMappings?.length ?? 0}
                      canDelete={inputMappings?.length > 1}
                      onAdd={() => {
                        setValue('configuration.inputMappings', [
                          ...inputMappings,
                          { type: FieldType.String, required: true }
                        ]);
                      }}
                      onDelete={() =>
                        setValue(
                          'configuration.inputMappings',
                          inputMappings.filter((_, i) => i !== index)
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {headersConfig && (
        <FormArray
          className="grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2"
          item={headersConfig}
        />
      )}
      {parametersConfig && (
        <FormArray
          className="grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2"
          item={parametersConfig}
        />
      )}

      {method === 'POST' && (
        <div>
          <Label>JSON payload</Label>
          <Editor
            placeholder="Enter value..."
            language={json()}
            defaultDoc={(watch('configuration.body') as string) ?? ''}
            onUpdate={value => setValue('configuration.body', value)}
            resizable={false}
            className="cm-nohighlight rounded border border-gray-300 bg-white"
          />
          {!!jsonError && <p className="mt-1 text-red-500">{jsonError}</p>}
        </div>
      )}
      <div>
        <EditPermission>
          <MyInput
            {...register('configuration.healthcheck')}
            label={fields?.['healthcheck']?.title}
            description={fields?.['healthcheck']?.description}
            errors={errors}
          />
        </EditPermission>
      </div>
    </SideBySide>
  );
}

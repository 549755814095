import { FormBuilder, SideBySide } from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { OauthButton } from '../connection-components';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
}

export function OauthConnectionConfig(props: Props) {
  const isBottom = [
    'pardot',
    'quickbooks',
    'salesforce',
    'netsuiteopenair',
    'zendesk_chat'
  ].includes(props.connectionType.id);
  return (
    <SideBySide hasSectionWrap maxWidth="max-w-xs" styles="space-y-3">
      {!isBottom && (
        <OauthButton
          isConnected={props.saved || !!props.connection?.saved}
          oauthLoading={props.oauthLoading}
          connectionTypeName={props.connectionType.name}
          onSave={props.onSave}
        />
      )}
      <FormBuilder<ConnectionFormValues>
        features={props.features}
        schema={props.connectionType.configurationSchema}
        prefix="configuration"
        promiseOptions={props.promiseOptions}
        styleOverrides={{
          'configuration.accounts': 'relative w-[32rem]',
          'configuration.repos': 'relative w-[32rem]'
        }}
      />
      {isBottom && (
        <OauthButton
          className="pt-2"
          isConnected={props.saved || !!props.connection?.saved}
          oauthLoading={props.oauthLoading}
          connectionTypeName={props.connectionType.name}
          onSave={props.onSave}
        />
      )}
    </SideBySide>
  );
}
